import React, { useEffect, useRef } from 'react'
import type ReCaptcha from 'react-google-recaptcha'

import { ReCaptchaField } from '@elo-kit/components'

import { useShopStores } from 'shop/hooks/use-store'

interface Props {
  isDealPage?: boolean
}

export const CheckoutRecaptcha: React.FC<Props> = ({ isDealPage }) => {
  const { paymentStore, dealsStore } = useShopStores()

  const store = isDealPage ? dealsStore : paymentStore
  const recaptchaRef = useRef<ReCaptcha>(null)
  const showRecaptcha = store.showRecaptcha()

  useEffect(() => {
    if (recaptchaRef?.current) {
      store.setRecaptchaRef(recaptchaRef.current)
    }

    return () => {
      store.setRecaptchaRef(null)
    }
  }, [recaptchaRef?.current, showRecaptcha])

  return showRecaptcha && <ReCaptchaField recaptchaRef={recaptchaRef} locale={I18n.currentLocale()} />
}

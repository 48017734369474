import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import classNames from 'classnames'
import { getCookie } from 'cookies-next'

import { ProductAccess } from '@elo-kit/components/product-access/ProductAccess'
import { ORDER_RATES_STATES } from 'constants/orderRates.constants'
import { THEME_FORMS } from 'constants/themes.constants'
import { PRODUCT_PASSWORD_OPTION_KEY } from 'constants/options.constants'
import {
  ORDER_BUMPS_POSITIONS_TYPES,
  ORDER_BUMPS_POSITIONS_DEFAULT,
  PAYMENT_PAGE_LAYOUT,
} from 'constants/paymentPageTemplates.constants'
import { EMBED_TYPES, HIDE_VALUE } from 'constants/embeddableItemsShared.constants'
import { PAYMENT_STATES } from 'constants/ordersShared.constants'
import { INVOICE_STATES } from 'constants/invoices.constants'
import { ORDER_BUMPS_PREVIEW_TYPES } from 'constants/orderBumpsShared.constants'
import {
  PRODUCT_TYPE_IDS,
  SHOP_ITEM_PRODUCT_EXPANDS,
  SHOP_ITEM_PRODUCT_WITH_UPSELLS_EXPANDS,
  UPSELLS_TYPES,
} from 'constants/productsShared.constants'
import { ROOT_URL } from 'constants/general.constants'

import { isWindowEnv } from 'utils/env.utils'
import { getSellerLink } from 'utils/helpersShared.utils'
import { normalizeShopLink } from 'utils/link.utils'
import { cookiesKey } from 'libs/common/cookies'
import { composeProps } from 'shop/utils/compose-props.utils'
import { authorisedSSRRequest } from 'shop/utils/authorised-SSR-request'
import { withCommonStores } from 'shop/utils/with-common-stores.utils'
import { withLocale } from 'shop/utils/with-locale.utils'
import { withRootStore } from 'shop/utils/with-root-store.utils'
import { withScreenSize } from 'shop/utils/with-screen-size.utils'
import { withPaymentPage } from 'shop/utils/with-payment-page'
import { withCacheControl } from 'shop/utils/cache-control.utils'
import { withGSSPLogger } from 'shop/utils/logger.utils'
import { useShopStores } from 'shop/hooks/use-store'
import { useNextRouter } from 'shop/hooks/use-next-js-router'
import { withUserSessionId } from 'shop/utils/with-user-session-id.utils'
import { withEnvVars } from 'shop/utils/withEnvVars'
import { InvoicePaid } from 'shop/components/payment/new/InvoicePaid'
import { Default } from 'shop/components/payment/new/templates/Default'
import { Simplified } from 'shop/components/payment/new/templates/Simplified'
import { TwoSteps } from 'shop/components/payment/new/templates/TwoSteps'
import { TwoStepsAdvanced } from 'shop/components/payment/new/templates/TwoStepsAdvanced'
import { AffiliateTracker } from 'shop/components/AffiliateTracker'
import { ProductSchema } from 'shop/components/schema-markup/ProductSchema'
import { EventSchema } from 'shop/components/schema-markup/EventSchema'
import { CheckoutRecaptcha } from 'shop/components/payment/new/recaptcha/CheckoutRecaptcha'

import { withApiClient } from 'shop/utils/with-api-client.utils'
import { withLogger } from 'shop/utils/with-logger.utils'
import { withShopThemeStore } from 'shop/utils/with-shop-theme-store.utils'
import { THEME_PAGE_TYPES } from 'shop/stores/shopTheme.store'
import { withAffiliateRedirection } from 'shop/utils/with-affiliate-redirection'
import { withExperiments } from 'shop/utils/with-experiments.utils'
import { withTranslations } from 'shop/utils/with-translations.utils'

interface Props {
  productLink: {
    sellerUsername: string
    productId: string
  }
  screenSize: string
}

interface RouterParams {
  username: string
  productId: string
  is_preview: string
  cabinet_preview: string
}
// TODO: check - productLink is used in widgets (/cabinet/embeddable_items/new)
const CheckOutPage: React.FC<Props> = observer(({ screenSize /* , productLink */ }) => {
  const { themeStore, paymentStore, sellerStore, shopThemeStore, trackingUserEventsStore, currenciesStore } =
    useShopStores()
  const [iframeData, setIframeData] = useState(null)
  const { params } = useNextRouter<RouterParams>()

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    if (params.cabinet_preview) {
      window.addEventListener('message', handleMessage)
    }

    return () => {
      paymentStore.resetTickets()
      paymentStore.resetSelectedUpsell()
      paymentStore.resetUpsell()
      window.removeEventListener('resize', handleResize)

      if (params.cabinet_preview) {
        window.removeEventListener('message', handleMessage)
      }
    }
  }, [])

  useEffect(() => {
    trackingUserEventsStore.logEvent({
      pageType: 'shop_checkout',
      eventType: 'page_view',
      withExperimentDetails: true,
    })

    trackingUserEventsStore.logLegacyEvent({
      actionType: 'shop_payment',
      sellerId: sellerStore.item.id,
      productId: paymentStore.product?.id,
    })
  }, [])

  const handleMessage = (e) => {
    if (e.origin === ROOT_URL) {
      setIframeData((prevData) => {
        if (prevData && JSON.parse(e.data).paymentPageTemplateId !== prevData.paymentPageTemplateId) {
          themeStore.fetchPaymentPageTemplate(sellerStore.item.username, JSON.parse(e.data).paymentPageTemplateId)
        }

        if (e.data) {
          try {
            return JSON.parse(e.data)
          } catch {}
        }
      })
    }
  }

  const getOrderBumpMobileThreshold = (): number => {
    const activeUpsellPosition = themeStore.ppTemplate?.theme?.upsellPosition || ORDER_BUMPS_POSITIONS_DEFAULT

    switch (themeStore.ppTemplate?.layout) {
      case PAYMENT_PAGE_LAYOUT.simplified: {
        switch (activeUpsellPosition) {
          case ORDER_BUMPS_POSITIONS_TYPES.belowPayerDetails:
            return 800
          case ORDER_BUMPS_POSITIONS_TYPES.belowPaymentMethods:
            return 1200
          case ORDER_BUMPS_POSITIONS_TYPES.belowProductDetails:
            return 800
          default:
            return 800
        }
      }

      case PAYMENT_PAGE_LAYOUT.twoSteps: {
        switch (activeUpsellPosition) {
          case ORDER_BUMPS_POSITIONS_TYPES.belowPayerDetails:
            return 1200
          case ORDER_BUMPS_POSITIONS_TYPES.belowPaymentMethods:
            return 1200
          case ORDER_BUMPS_POSITIONS_TYPES.belowProductDetails:
            return 800
          default:
            return 800
        }
      }

      default:
      case PAYMENT_PAGE_LAYOUT.default: {
        switch (activeUpsellPosition) {
          case ORDER_BUMPS_POSITIONS_TYPES.belowPayerDetails:
            return 1500
          case ORDER_BUMPS_POSITIONS_TYPES.belowPaymentMethods:
            return 1500
          case ORDER_BUMPS_POSITIONS_TYPES.belowProductDetails:
            return 995
          default:
            return 995
        }
      }
    }
  }

  const getOrderBumpPreviewMode = (): string => {
    if (!isWindowEnv()) {
      return screenSize === 'mobile' ? ORDER_BUMPS_PREVIEW_TYPES.mobile : ORDER_BUMPS_PREVIEW_TYPES.desktop
    }

    const threshold = getOrderBumpMobileThreshold()

    return window.innerWidth <= threshold ? ORDER_BUMPS_PREVIEW_TYPES.mobile : ORDER_BUMPS_PREVIEW_TYPES.desktop
  }

  const [orderBumpPreviewMode, setOrderBumpPreviewMode] = useState(getOrderBumpPreviewMode())

  const handleResize = () => {
    setOrderBumpPreviewMode(getOrderBumpPreviewMode())
  }

  if (paymentStore.invoice?.token) {
    if (paymentStore.invoice?.isPaid) {
      return (
        <InvoicePaid footer={paymentStore.product?.checkoutHtmlFooter} header={paymentStore.product?.checkoutHtml} />
      )
    }
  }

  if (sellerStore.protectByPass && sellerStore.isAppActive(PRODUCT_PASSWORD_OPTION_KEY)) {
    return <ProductAccess password={sellerStore.productPassword} setPasswordIsValid={sellerStore.setProtectByPass} />
  }

  if (sellerStore.lockedByToken) {
    return (
      <div className='container'>
        <div className='row payment-protected'>{I18n.t('react.shop.payment.localed_by_token')}</div>
      </div>
    )
  }

  const isModalEmbed = paymentStore.store?.props?.embedType === EMBED_TYPES.modal

  const paymentPageWrapperClasses = classNames(
    `container-${themeStore.ppTemplate?.layout || PAYMENT_PAGE_LAYOUT.default}`
  )

  const paymentPageClasses = classNames('payment-page', {
    'modal-embed': isModalEmbed,
    embeded: !!paymentStore.store?.props?.embedType,
    [PAYMENT_PAGE_LAYOUT.twoSteps]: themeStore.ppTemplate?.layout === PAYMENT_PAGE_LAYOUT.twoSteps,
    simplified: themeStore.ppTemplate?.layout === PAYMENT_PAGE_LAYOUT.simplified,
    default: themeStore.ppTemplate?.layout === PAYMENT_PAGE_LAYOUT.default,
    [PAYMENT_PAGE_LAYOUT.twoStepsAdvanced]: themeStore.ppTemplate?.layout === PAYMENT_PAGE_LAYOUT.twoStepsAdvanced,
    'payment-page--no-margin': shopThemeStore.headerHidden || params.cabinet_preview,
    'payment-page--footer-with-header':
      !shopThemeStore.headerHidden && shopThemeStore.shopTheme?.form === THEME_FORMS.custom,
    'cabinet-preview': params.cabinet_preview,
    [`cabinet-preview--${iframeData?.previewType || 'mobile'}`]: iframeData?.previewType || params.cabinet_preview,
  })

  const templates = {
    [PAYMENT_PAGE_LAYOUT.default]: Default,
    [PAYMENT_PAGE_LAYOUT.simplified]: Simplified,
    [PAYMENT_PAGE_LAYOUT.twoSteps]: TwoSteps,
    [PAYMENT_PAGE_LAYOUT.twoStepsAdvanced]: TwoStepsAdvanced,
  }

  const ActiveTemplate = templates[themeStore.ppTemplate?.layout || PAYMENT_PAGE_LAYOUT.default]
  const activeUpsellPosition = themeStore.ppTemplate?.theme?.upsellPosition || ORDER_BUMPS_POSITIONS_DEFAULT
  const isTicket = paymentStore.product?.form === PRODUCT_TYPE_IDS.eventTickets

  return (
    <AffiliateTracker params={{ username: params.username, slug: params.productId, product_slug: params.productId }}>
      {isTicket ? (
        <EventSchema
          productName={paymentStore.product.name}
          productDescription={paymentStore.product.description}
          productImage={paymentStore.product.covers?.[0]?.cover}
          tickets={paymentStore.product.tickets.map((ticket) => ({
            ticketDates: ticket.ticketDates,
            locationAddress: ticket?.locationAddress || ticket?.locationShortName,
            countryCode: ticket?.countryCode,
            ticketName: ticket?.name,
            isOnline: ticket?.online,
            plans: ticket.pricingPlans.map((price) => ({
              productPrice: price.prefs.price || price.prefs.firstAmount,
              productCurrency: currenciesStore.getKey(price.currencyId),
            })),
          }))}
        />
      ) : (
        <ProductSchema
          productName={paymentStore.product.name}
          productDescription={paymentStore.product.shortDescription}
          productImage={paymentStore.product.covers?.[0]?.cover}
          productPrice={`${paymentStore.product.displayPrice}`}
          productCurrency={currenciesStore.getKey(paymentStore.product.displayCurrencyId)}
        />
      )}
      {themeStore.ppTemplate?.theme?.paymentPageColor && (
        <style
          dangerouslySetInnerHTML={{
            __html: `
                  div.payment-page .two_steps .steps-container {
                    background-color: ${themeStore.ppTemplate?.theme?.paymentBgColor};
                  }
                  div.payment-page .embeded-page-wrapper {
                    background-color: ${themeStore.ppTemplate?.theme?.paymentBgColor};
                  }
                  div.payment-page {
                    background-color: ${themeStore.ppTemplate?.theme?.paymentBgColor};
                  }
                  div.payment-page .payment-tickets .cut-out-left,
                  div.payment-page .payment-tickets .cut-out-right {
                    background-color: ${themeStore.ppTemplate?.theme?.paymentBgColor};
                  }
                  div.payment-page .header.selected {
                    border-color: ${themeStore.ppTemplate?.theme?.paymentPageColor};
                  }
                `,
          }}
        />
      )}

      {themeStore.ppTemplate?.theme?.customCss && (
        <style dangerouslySetInnerHTML={{ __html: `${themeStore.ppTemplate.theme.customCss}` }}></style>
      )}

      <div className={paymentPageClasses}>
        <div className={paymentPageWrapperClasses}>
          {params.is_preview && !params.cabinet_preview && (
            <div className='preview-warning'>
              <div>
                <i className='fa fa-exclamation-circle' />
              </div>
              <div>
                <span>{I18n.t('react.shop.payment.preview_warning')}</span>
              </div>
            </div>
          )}
          <ActiveTemplate
            activeUpsellPosition={activeUpsellPosition}
            orderBumpPreviewMode={orderBumpPreviewMode}
            manageLink={`/s/${params.username}/payment/${paymentStore.buildedOrder?.token}/manage`}
            iframeData={iframeData}
            cabinetPreview={!!params.cabinet_preview}
            themeStore={themeStore}
          />

          <CheckoutRecaptcha />
        </div>
      </div>
    </AffiliateTracker>
  )
})

export default CheckOutPage

const getPageProps = async (context) => {
  const {
    productId: productSlug,
    username,
    upsells: upsellIds,
    bundles: bundleIds,
    preselect_upsells: urlPreselectedUpsells,
    is_preview: isPreview,
  } = context.query
  const shopThemeId = context.query.shop_theme_id || context.rootStore.sellerStore.item.shopThemeId

  await context.rootStore.shopThemeStore.fetchPayerForms(username, shopThemeId)
  await context.rootStore.paymentCountriesStore.fetchPaymentCountries(username)

  const hasAccessTokenCookie = !!getCookie(cookiesKey('access_token'), { req: context.req, res: context.res })
  const forwarded = context.req.headers['x-forwarded-for']
  const ip = forwarded ? forwarded.split(/, /)[0] : context.req.connection.remoteAddress

  await context.rootStore.productsStore.fetchProduct(productSlug, '', isPreview === 'true', undefined, true)

  await context.rootStore.productSettingsStore.fetchItem({
    username,
    id: context.rootStore.productsStore.product.id,
  })

  if (context.rootStore.productsStore.product.id) {
    if (hasAccessTokenCookie) {
      await authorisedSSRRequest(
        async (accessToken: string) =>
          await context.rootStore.paymentStore.fetchPayment(
            username,
            productSlug,
            { ...context.query, ip },
            accessToken
          ),
        context
      )
    } else {
      await context.rootStore.paymentStore.fetchPayment(username, productSlug, { ...context.query, ip })
    }
  }

  const noDownload =
    context.rootStore.productsStore.product?.form === PRODUCT_TYPE_IDS.download &&
    !context.rootStore.productsStore.product?.hasDigitals

  // TODO: to getter -> isInvalidProduct
  const shouldRedirectToShop =
    !context.rootStore.productsStore.product?.id ||
    context.rootStore.productsStore.product?.limitForSale === 0 ||
    (context.rootStore.productsStore.product?.form !== PRODUCT_TYPE_IDS.eventTickets &&
      context.rootStore.productsStore.product?.limitForSale < 0) ||
    (!context.rootStore.productsStore.product.free &&
      context.rootStore.productsStore.product?.form !== PRODUCT_TYPE_IDS.eventTickets &&
      context.rootStore.productsStore.product?.pricingPlans.length === 0) ||
    (context.rootStore.productsStore.product?.form === PRODUCT_TYPE_IDS.eventTickets &&
      context.rootStore.productsStore.product?.tickets.length === 0) ||
    noDownload

  const shouldRedirectToAnotherPage = shouldRedirectToShop && !!context.rootStore.productsStore.product?.redirectType

  if (shouldRedirectToAnotherPage && !context.query.is_preview && !context.query.cabinet_preview) {
    let shopLink = normalizeShopLink(getSellerLink(context.rootStore.sellerStore.item))
    if (context.rootStore.productsStore.product.redirectType === 'internal') {
      shopLink = normalizeShopLink(`${context.rootStore.productsStore.product.redirectUrl}?showUnavailableWarning=true`)
    }
    if (context.rootStore.productsStore.product.redirectType === 'external') {
      shopLink = normalizeShopLink(
        getSellerLink(
          context.rootStore.sellerStore.item,
          `${context.rootStore.productsStore.product?.slug || ''}?showProductPage=true`
        )
      )
    }

    return {
      redirect: {
        destination: shopLink,
        permanent: false,
      },
    }
  }

  if (shouldRedirectToShop && !context.query.is_preview && !context.query.cabinet_preview) {
    return {
      redirect: {
        destination: normalizeShopLink(getSellerLink(context.rootStore.sellerStore.item)),
        permanent: false,
      },
    }
  }

  context.rootStore.productUpsellsStore.setExpands([
    'upsell.pricing_plans',
    'ticket_date',
    'addon_theme',
    'ticket.pricing_plans',
    'pricing_plans',
  ])

  context.rootStore.productUpsellsStore.handleScopeChange('productId', context.rootStore.paymentStore.product?.id)

  if ([upsellIds, bundleIds].indexOf(HIDE_VALUE) === -1) {
    if (upsellIds || bundleIds) {
      context.rootStore.productUpsellsStore.handleScopeChange('upsellId', decodeURIComponent(upsellIds || bundleIds))
    }

    await context.rootStore.productUpsellsStore.fetchFullList({
      username: context.rootStore.sellerStore.item.username,
    })
  }

  const fetchUpsellProductsPromises = context.rootStore.productUpsellsStore.list?.map(async (productUpsell) => {
    const {
      ticket,
      ticketDate,
      ticketId,
      ticketDateId,
      ticketsCount,
      prefs,
      pricingPlans: ownPricingPlans,
    } = productUpsell

    const resp = await context.rootStore.productsStore.childApi.fetchProduct(
      context.rootStore.sellerStore.item.username,
      productUpsell.upsell?.slug,
      {
        forShop: true,
        expand: [...SHOP_ITEM_PRODUCT_EXPANDS, ...SHOP_ITEM_PRODUCT_WITH_UPSELLS_EXPANDS],
      }
    )

    const { data: upsellData } = resp || {}

    context.rootStore.productUpsellsStore.setUpsellProducts(upsellData)

    const upsellPreselected =
      context.rootStore.paymentStore.store?.props?.upsellId &&
      String(context.rootStore.paymentStore.store?.props?.upsellId) === String(productUpsell.upsellId)
    const predefinedUpsellPreselected =
      context.rootStore.paymentStore.store?.props?.bundleId &&
      String(context.rootStore.paymentStore.store?.props?.bundleId) === String(productUpsell?.id)

    let isPreselectedUpsellInUrl = false

    if (urlPreselectedUpsells) {
      const decodePreselectedUpsells = decodeURIComponent(urlPreselectedUpsells) || ''
      const preselectedUpsellIdsArray = decodePreselectedUpsells.split(',')
      isPreselectedUpsellInUrl = preselectedUpsellIdsArray?.includes(String(productUpsell.upsellId))
    }
    const isBump = context.rootStore.productSettingsStore.item.prefs?.upsellType === UPSELLS_TYPES.bump

    const bumpIsRequired = isBump && productUpsell.prefs?.required
    const prefetchedUpsellProduct = context.rootStore.productUpsellsStore.upsellProducts.find(
      (product) => product?.id === productUpsell.upsell?.id
    )
    const getPricingPlanSuit = () => {
      const isOwnPlansActive = productUpsell.prefs.ownPlans || false
      const isEvent = prefetchedUpsellProduct?.form === PRODUCT_TYPE_IDS.eventTickets

      if (isOwnPlansActive) {
        const customPrices = productUpsell.prices || []
        if (customPrices.length > 0) {
          return customPrices.map((price) => ({
            priceId: price?.id,
            ...price.pricingPlan,
          }))
        }
        return productUpsell?.pricingPlans || []
      }

      if (isEvent && productUpsell.ticket) {
        return productUpsell.ticket?.pricingPlans
      }

      return prefetchedUpsellProduct?.pricingPlans
    }

    if (upsellPreselected || predefinedUpsellPreselected || bumpIsRequired || isPreselectedUpsellInUrl) {
      const selectedTicket = ticketId && upsellData?.tickets.find((ticket) => ticket?.id === ticketId)

      const { planId } = context.rootStore.paymentStore.getSelectedUpsell(
        context.rootStore.paymentStore.store?.upsell,
        productUpsell?.id,
        'productUpsellId'
      )
      const selectedPricingPlanId = planId
        ? (getPricingPlanSuit() || []).find((planItem) => String(planItem?.id) === String(planId))
        : ((getPricingPlanSuit() || [])[0] && getPricingPlanSuit()[0]) || {}

      const selectedPricingPlan =
        selectedPricingPlanId?.id || upsellData?.pricingPlans[0]?.id || selectedTicket?.pricingPlans[0]?.id

      const ticketInfo = ticketId
        ? {
            ticket,
            ticketDate,
            ticketId,
            ticketDateId,
            ticketsCount,
          }
        : {}
      const selectedUpsell = context.rootStore.productUpsellsStore.list?.filter(
        (productUpsell) => productUpsell.upsell?.id === upsellData?.id
      )[0]
      const position = upsellData?.position || selectedUpsell?.position
      const currentUpsell = prefs?.ownPlans
        ? {
            ...upsellData,
            pricingPlans: [...ownPricingPlans],
          }
        : upsellData

      await context.rootStore.paymentStore.selectUpsell(
        currentUpsell,
        selectedPricingPlan,
        productUpsell?.id,
        position,
        ticketInfo
      )
    }
  })

  await Promise.all(fetchUpsellProductsPromises)

  await context.rootStore.paymentStore.recalculatePrices()

  const isCanceled =
    (context.rootStore.paymentStore.invoice?.state === INVOICE_STATES.canceled ||
      context.rootStore.paymentStore.buildedOrder?.paymentState === PAYMENT_STATES.paymentCanceled) &&
    !context.rootStore.paymentStore.buildedOrder?.orderRates?.some(({ state }) => state === ORDER_RATES_STATES.debt)

  if (isCanceled) {
    return {
      redirect: {
        destination: `/s/${username}/payment/${context.rootStore.paymentStore.buildedOrder?.token}`,
        permanent: false,
      },
    }
  }

  return {
    props: {
      isProductPage: true,
      isCheckoutPage: true,
      initialData: {
        productsStore: {
          product: toJS(context.rootStore.productsStore.product),
        },
        productSettingsStore: {
          item: toJS(context.rootStore.productSettingsStore.item),
        },
        productUpsellsStore: {
          list: toJS(context.rootStore.productUpsellsStore.list),
          upsellProducts: toJS(context.rootStore.productUpsellsStore.upsellProducts),
          expands: toJS(context.rootStore.productUpsellsStore.expands),
          loading: false,
        },
        // TODO: remove after this.root.themeStore.fetchPaymentPageTemplate
        themeStore: {
          data: toJS(context.rootStore.themeStore.data),
          ppTemplate: toJS(context.rootStore.themeStore.ppTemplate),
          displayData: toJS(context.rootStore.themeStore.displayData),
        },
        shopThemeStore: {
          shopTheme: toJS(context.rootStore.shopThemeStore.shopTheme),
          shopPages: toJS(context.rootStore.shopThemeStore.shopPages),
          payerForms: toJS(context.rootStore.shopThemeStore.payerForms),
          pageType: THEME_PAGE_TYPES.checkout,
        },
        sellerStore: {
          protectByPass: toJS(context.rootStore.sellerStore.protectByPass),
          productPassword: toJS(context.rootStore.sellerStore.productPassword),
          lockedByToken: toJS(context.rootStore.sellerStore.lockedByToken),
          item: toJS(context.rootStore.sellerStore.item),
        },
        cancellationTermsStore: {
          item: toJS(context.rootStore.cancellationTermsStore.item),
        },
        paymentStore: {
          buildedOrder: toJS(context.rootStore.paymentStore.buildedOrder),
          buildedOrderLoading: toJS(context.rootStore.paymentStore.buildedOrderLoading),
          buyBtnDisabled: toJS(context.rootStore.paymentStore.buyBtnDisabled),
          invoice: toJS(context.rootStore.paymentStore.invoice),
          isPurchaseLoading: toJS(context.rootStore.paymentStore.isPurchaseLoading),
          loading: toJS(context.rootStore.paymentStore.loading),
          payerForms: toJS(context.rootStore.paymentStore.payerForms),
          product: toJS(context.rootStore.paymentStore.product),
          selectedGroupedPricingPlan: toJS(context.rootStore.paymentStore.selectedGroupedPricingPlan),
          selectedUpsell: toJS(context.rootStore.paymentStore.selectedUpsell),
          stripeCardValid: toJS(context.rootStore.paymentStore.stripeCardValid),
          vatData: toJS(context.rootStore.paymentStore.vatData),
          store: toJS(context.rootStore.paymentStore.store),
          buildedOrderPms: toJS(context.rootStore.paymentStore.buildedOrderPms),
          paramsForSubmit: toJS(context.rootStore.paymentStore.paramsForSubmit),
          shouldResetIntent: toJS(context.rootStore.paymentStore.shouldResetIntent),
          newCheckoutSummary: toJS(context.rootStore.paymentStore.newCheckoutSummary),
        },
        paymentCountriesStore: {
          paymentCountries: toJS(context.rootStore.paymentCountriesStore.paymentCountries),
        },
      },
    },
  }
}

export const getServerSideProps = withGSSPLogger(
  composeProps(
    withLocale,
    withTranslations,
    withApiClient,
    withEnvVars,
    withUserSessionId,
    withRootStore,
    withScreenSize,
    withAffiliateRedirection,
    withPaymentPage,
    withCommonStores,
    withShopThemeStore,
    getPageProps,
    withExperiments,
    withCacheControl(5),
    withLogger
  ),
  'getServerSideProps CheckOutPage'
)
